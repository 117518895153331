<template>
      <div>
            <b-card class="mb-3">
                  <b-row>
                        <b-col sm="5">
                              <label for="">Campo</label>
                              <v-select v-model="form.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                                    :options="input" />
                        </b-col>
                        <b-col sm="5" v-if="showInput">
                              <label for="">{{ form.type.title }}</label>
                              <b-form-input v-model="form.name" placeholder="Escribir ..." />
                        </b-col>
                        <b-col sm="5" v-if="showInputDate">
                              <label for="">{{ form.type.title }}</label>
                              <b-form-datepicker v-model="form.name" class="mb-1" />
                        </b-col>
                        <b-col sm="2" class="mt-2">
                              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="search()"
                                    class="btn-icon rounded-circle">
                                    <feather-icon icon="SearchIcon" />
                              </b-button>
                        </b-col>
                  </b-row>
            </b-card>
            <b-card v-if="items.length > 0">
                  <b-table :items="items" striped responsive>
                        <template #cell(actions)="data">
                              <b-button variant="primary" class="btn-icon rounded-circle" @click="addNotes(data.item.id)"
                                    v-b-tooltip.hover.v-primary title="Agregar Notas" v-b-modal.modal-lg>
                                    <feather-icon icon="BookIcon" />
                              </b-button>
                        </template>
                  </b-table>
            </b-card>
            <b-card v-else>
                  <h5 class="text-center">No se encontraron registros</h5>
            </b-card>
            <b-modal ref="my-modal" hide-footer title="Agregar Notas a Pdf" size="lg">
                  <template class="p-2">
                        <quill-editor v-model="form.code" />
                  </template>
                  <b-row>
                        <b-col sm="12" class="mt-5 text-center mb-2">
                              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                                    @click="saveNotePdfVoucher(idVoucher)">
                                    guardar
                              </b-button>
                        </b-col>
                  </b-row>
            </b-modal>
      </div>
</template>
    
<script>
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import { BTable, BCard, BCol, BRow, BFormInput, BFormDatepicker, BButton, VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
      components: {
            BTable, BCard, vSelect, BCol, BRow, BFormInput, BFormDatepicker, BButton, quillEditor, VBTooltip
      },
      data() {
            return {
                  showInput: false,
                  showInputDate: false,
                  input: [
                        { value: 'first_name', title: 'Nombre' },
                        { value: 'birthday', title: 'Fecha de nacimiento' },
                        { value: 'documentIdentity', title: 'Numero documento' },
                        { value: 'phone', title: 'Telefono' },
                        { value: 'passport', title: 'Pasaporte' },
                        { value: 'created_at', title: 'Fecha emision' },
                  ],
                  form: {
                        type: null,
                        name: null
                  },
                  items: [],
                  id: this.$route.params && this.$route.params.id,
                  idVoucher: null,
                  form: {
                        code: null
                  },
            }
      },
      created() {
            this.asegurates()
      },
      watch: {
            "form.type": {
                  handler(val) {
                        if (val.value == 'birthday' || val.value == 'created_at') {
                              this.showInput = false
                              this.showInputDate = true
                        } else {
                              this.showInput = true
                              this.showInputDate = false
                        }

                  }
            }
      },
      directives: {
            Ripple,
            'b-tooltip': VBTooltip,
      },
      methods: {
            saveNotePdfVoucher(id) {
                  this.$http.post('/cotization/generate-code-pdf-voucher/' + id, this.form)
                        .then(response => {
                              if (response.data.code == 200) {
                                    this.$swal({
                                          icon: "success",
                                          title: response.data.message,
                                    });
                                    this.$refs['my-modal'].hide()
                              }
                              if (response.data.code == 500) {
                                    this.$swal({
                                          icon: "error",
                                          title: response.data.message,
                                    });
                              }
                        })
                        .catch((error) => {
                              this.errors = error.response.data.errors;
                        });
            },
            addNotes(id) {
                  this.$refs['my-modal'].show()
                  this.idVoucher = id
                  this.$http.post('voucher/note-pdf-vaucher/' + id).then((response) => {
                        this.form.code = response.data.noteVoucher.code
                  })
            },
            asegurates() {
                  this.$http.get('/voucher/asegurates/' + this.id).then((response) => {
                        this.items = response.data.asegurates
                  })
            },
            search() {
                  this.$http.post('/voucher/search', this.form)
                        .then((response) => {
                              if (response.data.code == 200) {
                                    if (response.data.asegurates.length == 0) {
                                          this.$swal({
                                                icon: "error",
                                                title: 'No se encontrarón registros'
                                          });
                                    } else {
                                          this.$swal({
                                                icon: "success",
                                                title: response.data.message
                                          });
                                          this.items = response.data.asegurates
                                    }
                              }
                              if (response.data.code == 500) {
                                    this.$swal({
                                          icon: "error",
                                          title: response.data.message
                                    });
                              }
                        })
                        .catch((error) => {
                              this.errors = error.response.data.errors;
                        });
            }
      }
}
</script>
<style>
.table {
      width: 120% !important;
      color: #6e6b7b;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
     
    